<template>
  <div class="content block-el">
    <template v-if="fetched">
      <PageHeader :title="`${$t('adm.customer.assign.header')} ${customer.name}`">
      <template v-slot:actions>
        <li>
          <a
            v-if="!attaching"
            @click="attach"
            href="javascript:void(0)"
            class="btn btn-success btn-floating"
          >
            <i
              class="fas fa-save text-white font-size-22 v-align-text-bottom"
            ></i>
          </a>
          <a
            v-else
            href="javascript:void(0)"
            class="btn btn-success btn-floating"
            disabled
          >
            <span class="qt-loader qt-loader-mini qt-loader-right"> </span>
          </a>
        </li>
      </template>
    </PageHeader>
      <div class="page-content container-fluid">
        <form @submit.prevent="save">
          <div class="row">
            <div class="col-6">
              <div class="card">
                <div class="card-header">{{$t('generic-str.menu.general')}}</div>
                <div class="card-body">
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label class="control-label text-left">{{$tc('generic-str.lbl-product', 2)}}</label>
                      <select-input
                        v-model="form.productId"
                        label="name"
                        :reduce="(product) => product.id"
                        :options="products"
                        :clearable="false"
                        v-on:option:selected="changeProduct"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label class="control-label text-left"
                        >{{$t('adm.customer.assign.cycle')}}</label
                      >
                      <select
                        class="form-control"
                        v-model="form.billing_cycle"
                        required
                      >
                        <option value="trial">{{$t('acc-settings.trial')}}</option>
                        <option value="prepaid">{{$t('adm.customer.assign.prepaid')}}</option>
                        <option selected value="weekly">{{$t('adm.customer.assign.weekly')}}</option>
                        <option value="biweekly">{{$t('adm.customer.assign.biweekly')}}</option>
                        <option value="monthly">{{$t('adm.customer.assign.monthly')}}</option>
                      </select>
                    </div>
                    <div class="form-group col-md-6">
                      <label class="control-label text-left">{{$t('adm.customer.assign.currency')}}</label>
                      <select
                        class="form-control"
                        v-model="form.currency"
                        required
                      >
                        <option selected value="brl">
                          {{$t('adm.customer.assign.currency-type.br')}}
                        </option>
                        <option value="usd">{{$t('adm.customer.assign.currency-type.us')}}</option>
                        <option value="eur">{{$t('adm.customer.assign.currency-type.eu')}}</option>
                      </select>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="name">{{$tc('adm.customer.assign.credit', 2)}}</label>
                      <input
                        type="number"
                        class="form-control"
                        :placeholder="$tc('adm.customer.assign.credit', 2)"
                        v-model="form.credits"
                        :disabled="form.unlimited"
                      />
                    </div>
                    <div class="form-group col-md-2">
                      <div
                        class="custom-control custom-checkbox form-check m-t-35"
                      >
                        <input
                          v-model="form.unlimited"
                          type="checkbox"
                          class="custom-control-input"
                          id="unlimited"
                        />
                        <label class="custom-control-label" for="unlimited"
                          >{{$t('adm.customer.assign.unlimited')}}</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="card">
                <div class="card-header">{{$tc('adm.customer.assign.price', 2)}}</div>
                <div v-if="type == 'sms' || type == 'rcs'" class="card-body">
                  <div class="form-row">
                    <div class="form-group col-12">
                      <label for="name">Claro</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.values.claro"
                        v-money="money"
                      />
                    </div>
                    <div class="form-group col-12">
                      <label for="name">Nextel</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.values.nextel"
                        v-money="money"
                      />
                    </div>
                    <div class="form-group col-12">
                      <label for="name">Oi</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.values.oi"
                        v-money="money"
                      />
                    </div>
                    <div class="form-group col-12">
                      <label for="name">Tim</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.values.tim"
                        v-money="money"
                      />
                    </div>
                    <div class="form-group col-12">
                      <label for="name">Vivo</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.values.vivo"
                        v-money="money"
                      />
                    </div>
                    <div class="form-group col-12">
                      <label for="name">{{$tc('generic-str.other-a', 2)}}</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.values.other"
                        v-money="money"
                      />
                    </div>
                  </div>
                </div>
                <div v-else-if="type == 'email'" class="card-body">
                  <div class="form-row">
                    <div class="form-group col-12">
                      <label for="name">{{$tc('generic-str.message', 1)}}</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.values.message"
                        v-money="money"
                      />
                    </div>
                    <div class="form-group col-12">
                      <label for="name">IP</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.values.ip"
                        v-money="money"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </template>
    <!-- <div v-else class="relative qt-block-ui" style="padding: 120px" /> -->
    <div v-else class="static qt-block-ui" style="padding: 120px" />
  </div>
</template>

<script>
// @ is an alias to /src
import Tab from '@/directives/Tab';
import PageHeader from '@/components/PageHeader.vue';
import CustomerService from '@/services/customer.service';
import ProductService from '@/services/admin/product.service';
import SelectInput from '@/components/SelectInput.vue';
import { mask } from 'vue-the-mask';

export default {
  name: 'AssignProduct',
  components: {
    PageHeader,
    SelectInput,
  },
  directives: {
    Tab,
    mask,
  },
  data() {
    return {
      fetched: false,
      isSending: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 6,
        masked: false,
      },
      products: [],
      type: '',
      customer: {},
      form: {
        billing_cycle: 'monthly',
        currency: 'brl',
        credits: 0,
        productId: {},
        values: {},
      },
      attaching: false,
      pages: 1,
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.fetched = false;
      CustomerService.getCustomer(this.$route.params.id).then(
        (customer) => {
          this.customer = customer;
          ProductService.getProducts().then(
            (products) => {
              this.products = products;
              this.form.productId = products[0].id;
              this.type = products[0].type;
              this.fetched = true;
            },
            (error) => {
              this.content = error;
            },
          );
        },
        (error) => {
          this.content = error;
        },
      );
    },
    changeProduct(product) {
      this.form.values = {};
      this.type = product.type;
    },
    attach() {
      this.attaching = true;
      CustomerService.attachProduct(this.$route.params.id, this.form)
        .then(
          () => {
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('adm.customer.assign.associated-prod'),
              type: 'success',
            });
            this.$router.push('../');
          },
          (error) => {
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
              content: error.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.attaching = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.loading-cep {
  right: 40px;
  top: 40px;
  position: absolute;
  padding: initial;
}
</style>
